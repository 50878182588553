.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.integral-page-sign .sign-up-all {
  background: #f9f9f9;
}
.integral-page-sign header {
  width: 100%;
  height: 2.88rem;
  font-size: 0.3rem;
  color: #fff;
  position: relative;
  background-image: url('../../../assets/asign.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.integral-page-sign header .head_rules {
  position: absolute;
  right: 0;
  top: 0.46rem;
  width: 1.24rem;
  height: 0.46rem;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 0.28rem 0 0 0.28rem;
  font-size: 0.24rem;
  text-align: center;
  line-height: 0.46rem;
}
.integral-page-sign header .isSign {
  position: absolute;
  top: 0.46rem;
  left: 0.4rem;
}
.integral-page-sign header .isSign span:nth-child(2) {
  font-size: 0.64rem !important;
}
.integral-page-sign header .isSign .sign-point {
  padding-bottom: 0.2rem;
}
.integral-page-sign header .point-detail {
  width: 1.24rem;
  height: 0.46rem;
  line-height: 0.46rem;
  text-align: center;
  font-size: 100%;
  position: absolute;
  right: 0;
  top: 1.2rem;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 0.28rem 0 0 0.28rem;
  font-size: 0.24rem;
}
.integral-page-sign header .all-number {
  line-height: 0.9rem;
  font-size: 0.64rem;
  color: #fff;
}
.integral-page-sign section {
  background: #fff;
  font-size: 0.26rem;
  color: #333;
}
.integral-page-sign section .integral-title {
  width: 100%;
  height: 0.84rem;
  line-height: 0.84rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  padding: 0 0.3rem;
}
.integral-page-sign section .integral-list {
  padding: 0 0.3rem;
}
.integral-page-sign section .integral-list .integral-item {
  width: 100%;
  height: 1.2rem;
  padding: 0.24rem 0 0.18rem 0;
  border-bottom: 1px solid #F0F0F0;
  /*增加*/
  /* 减少*/
}
.integral-page-sign section .integral-list .integral-item .left-info {
  height: 100%;
}
.integral-page-sign section .integral-list .integral-item .time {
  font-size: 0.22rem;
  color: #999;
}
.integral-page-sign section .integral-list .integral-item .increase {
  font-size: 0.44rem;
  color: #FF5252;
}
.integral-page-sign section .integral-list .integral-item .decrease {
  font-size: 0.44rem;
  color: var(--main-color);
}
.integral-page-sign section .yd-list-loading {
  background: #f5f5f5;
}
