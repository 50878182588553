.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.sign-up {
  width: 7.1rem;
  height: 3.28rem;
  background: #ffffff;
  margin: auto;
  position: relative;
  top: -0.68rem;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .reissue-success {
  letter-spacing: 0.02rem;
}
.sign-up .reissue-success .toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.sign-up .reissue-success .toast {
  width: 4.68rem;
  height: 2.32rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-up .reissue-success .toast .toast-container {
  text-align: center;
}
.sign-up .reissue-success .toast .toast-container .success-txt {
  font-size: 0.36rem;
  font-weight: bold;
  color: #005B8A;
  margin-bottom: 0.2rem;
}
.sign-up .reissue-success .toast .toast-container .success-point {
  font-size: 0.24rem;
  font-weight: 500;
  color: #333333;
}
.sign-up .sign-up-title {
  width: 7.1rem;
  height: 3.28rem;
  background: #ffffff;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .sign-up-title .sign-up-tab {
  height: 0.68rem;
  padding-top: 0.34rem;
  display: flex;
  align-items: flex-end;
}
.sign-up .sign-up-title .sign-up-tab .tab-left {
  margin-left: 0.24rem;
  color: #333333;
  font-size: 0.3rem;
  font-weight: 500;
}
.sign-up .sign-up-title .sign-up-tab .tab-right {
  font-size: 0.24rem;
  color: #9c9c9c;
}
.sign-up .sign-up-title .sign-up-tab .tab-right .point-color {
  color: #005B8A;
}
.sign-up .sign-up-title .continued-days {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0 0.5rem 0;
}
.sign-up .sign-up-title .continued-days .days-detail {
  position: relative;
}
.sign-up .sign-up-title .continued-days .days-detail .detail-point {
  text-align: center;
  padding-top: 0.1rem;
  width: 0.92rem;
  height: 0.92rem;
  color: #B5B5B5;
  background-size: 100% 100%;
  margin-bottom: 0.18rem;
}
.sign-up .sign-up-title .continued-days .days-detail .age_no_sign {
  background-image: url("../../../assets/ago_no_sign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .no_sign {
  background-image: url("../../../assets/noSign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .is_sign {
  background-image: url("../../../assets/isSign.png");
  color: #005B8A;
}
.sign-up .sign-up-title .continued-days .days-detail .age_is_sign {
  background-image: url("../../../assets/ago_is_asign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .no_reissue {
  width: 0.92rem;
  height: 0.92rem;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0.92rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
}
.sign-up .sign-up-title .continued-days .days-detail .detail-time {
  text-align: center;
}
.sign-up .sign-up-title .continued-days .days-detail .data-color {
  color: #005B8A;
}
.sign-up .exchange-book {
  margin-top: 0.2rem;
  padding-bottom: 0.26rem;
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .exchange-book .book-amount {
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  color: #9c9c9c;
  line-height: 0.44rem;
  padding: 0.26rem 0 0.26rem 0.3rem;
}
.sign-up .exchange-book .book-amount .sign-card-num {
  color: #005B8A;
}
.sign-up .exchange-book .exchange {
  color: #333;
}
.sign-up .exchange-book .book-content {
  margin: 0 0.3rem;
}
.sign-up .exchange-book .book-content .border {
  border-bottom: 0.02px solid #EBEBEB;
}
.sign-up .exchange-book .book-content .book-cycle {
  width: 100%;
  height: 1.2rem;
  padding: 0.3rem 0.1rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  position: relative;
}
.sign-up .exchange-book .book-content .book-cycle .book-logo {
  width: 0.72rem;
  height: 0.6rem;
}
.sign-up .exchange-book .book-content .book-cycle .book-logo img {
  width: 100%;
  height: 100%;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail {
  padding-left: 0.38rem;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .detail-type {
  padding-bottom: 0.1rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 400;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .card-txt {
  padding: 0;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .explain {
  font-size: 0.22rem;
  color: #9C9C9C;
  font-weight: 400;
}
.sign-up .exchange-book .book-content .book-cycle .content-number {
  color: #333;
  font-size: 0.32rem;
  position: absolute;
  right: 1.8rem;
}
.sign-up .exchange-book .book-content .book-cycle .content-btn {
  width: 1.28rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  text-align: center;
  line-height: 0.56rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #005B8A;
  position: absolute;
  right: 0.15rem;
  border: 0.02rem solid #005b8a;
}
