.border-none[data-v-5f3efe7b]:before {
  display: none;
}
.border-none[data-v-5f3efe7b]:after {
  display: none;
}
.flex-fix[data-v-5f3efe7b] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-5f3efe7b]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-5f3efe7b] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-5f3efe7b] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-5f3efe7b] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-5f3efe7b] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-5f3efe7b] {
  *zoom: 1;
}
.clear-fix[data-v-5f3efe7b]:before,
.clear-fix[data-v-5f3efe7b]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.sign-up[data-v-5f3efe7b] {
  width: 7.1rem;
  height: 3.28rem;
  background: #ffffff;
  margin: auto;
  position: relative;
  top: -0.68rem;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .reissue-success[data-v-5f3efe7b] {
  letter-spacing: 0.02rem;
}
.sign-up .reissue-success .toast-mask[data-v-5f3efe7b] {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.sign-up .reissue-success .toast[data-v-5f3efe7b] {
  width: 4.68rem;
  height: 2.32rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-up .reissue-success .toast .toast-container[data-v-5f3efe7b] {
  text-align: center;
}
.sign-up .reissue-success .toast .toast-container .success-txt[data-v-5f3efe7b] {
  font-size: 0.36rem;
  font-weight: bold;
  color: #005B8A;
  margin-bottom: 0.2rem;
}
.sign-up .reissue-success .toast .toast-container .success-point[data-v-5f3efe7b] {
  font-size: 0.24rem;
  font-weight: 500;
  color: #333333;
}
.sign-up .sign-up-title[data-v-5f3efe7b] {
  width: 7.1rem;
  height: 3.28rem;
  background: #ffffff;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .sign-up-title .sign-up-tab[data-v-5f3efe7b] {
  height: 0.68rem;
  padding-top: 0.34rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.sign-up .sign-up-title .sign-up-tab .tab-left[data-v-5f3efe7b] {
  margin-left: 0.24rem;
  color: #333333;
  font-size: 0.3rem;
  font-weight: 500;
}
.sign-up .sign-up-title .sign-up-tab .tab-right[data-v-5f3efe7b] {
  font-size: 0.24rem;
  color: #9c9c9c;
}
.sign-up .sign-up-title .sign-up-tab .tab-right .point-color[data-v-5f3efe7b] {
  color: #005B8A;
}
.sign-up .sign-up-title .continued-days[data-v-5f3efe7b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 0.5rem 0 0.5rem 0;
}
.sign-up .sign-up-title .continued-days .days-detail[data-v-5f3efe7b] {
  position: relative;
}
.sign-up .sign-up-title .continued-days .days-detail .detail-point[data-v-5f3efe7b] {
  text-align: center;
  padding-top: 0.1rem;
  width: 0.92rem;
  height: 0.92rem;
  color: #B5B5B5;
  background-size: 100% 100%;
  margin-bottom: 0.18rem;
}
.sign-up .sign-up-title .continued-days .days-detail .age_no_sign[data-v-5f3efe7b] {
  background-image: url("../../../assets/ago_no_sign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .no_sign[data-v-5f3efe7b] {
  background-image: url("../../../assets/noSign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .is_sign[data-v-5f3efe7b] {
  background-image: url("../../../assets/isSign.png");
  color: #005B8A;
}
.sign-up .sign-up-title .continued-days .days-detail .age_is_sign[data-v-5f3efe7b] {
  background-image: url("../../../assets/ago_is_asign.png");
}
.sign-up .sign-up-title .continued-days .days-detail .no_reissue[data-v-5f3efe7b] {
  width: 0.92rem;
  height: 0.92rem;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0.92rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
}
.sign-up .sign-up-title .continued-days .days-detail .detail-time[data-v-5f3efe7b] {
  text-align: center;
}
.sign-up .sign-up-title .continued-days .days-detail .data-color[data-v-5f3efe7b] {
  color: #005B8A;
}
.sign-up .exchange-book[data-v-5f3efe7b] {
  margin-top: 0.2rem;
  padding-bottom: 0.26rem;
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.1rem 0.1rem 0 0;
}
.sign-up .exchange-book .book-amount[data-v-5f3efe7b] {
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 500;
  color: #9c9c9c;
  line-height: 0.44rem;
  padding: 0.26rem 0 0.26rem 0.3rem;
}
.sign-up .exchange-book .book-amount .sign-card-num[data-v-5f3efe7b] {
  color: #005B8A;
}
.sign-up .exchange-book .exchange[data-v-5f3efe7b] {
  color: #333;
}
.sign-up .exchange-book .book-content[data-v-5f3efe7b] {
  margin: 0 0.3rem;
}
.sign-up .exchange-book .book-content .border[data-v-5f3efe7b] {
  border-bottom: 0.02px solid #EBEBEB;
}
.sign-up .exchange-book .book-content .book-cycle[data-v-5f3efe7b] {
  width: 100%;
  height: 1.2rem;
  padding: 0.3rem 0.1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.2rem 0;
  position: relative;
}
.sign-up .exchange-book .book-content .book-cycle .book-logo[data-v-5f3efe7b] {
  width: 0.72rem;
  height: 0.6rem;
}
.sign-up .exchange-book .book-content .book-cycle .book-logo img[data-v-5f3efe7b] {
  width: 100%;
  height: 100%;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail[data-v-5f3efe7b] {
  padding-left: 0.38rem;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .detail-type[data-v-5f3efe7b] {
  padding-bottom: 0.1rem;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 400;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .card-txt[data-v-5f3efe7b] {
  padding: 0;
}
.sign-up .exchange-book .book-content .book-cycle .content-detail .explain[data-v-5f3efe7b] {
  font-size: 0.22rem;
  color: #9C9C9C;
  font-weight: 400;
}
.sign-up .exchange-book .book-content .book-cycle .content-number[data-v-5f3efe7b] {
  color: #333;
  font-size: 0.32rem;
  position: absolute;
  right: 1.8rem;
}
.sign-up .exchange-book .book-content .book-cycle .content-btn[data-v-5f3efe7b] {
  width: 1.28rem;
  height: 0.56rem;
  border-radius: 0.28rem;
  text-align: center;
  line-height: 0.56rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #005B8A;
  position: absolute;
  right: 0.15rem;
  border: 0.02rem solid #005b8a;
}
